<template>
  <div class="container mescroll-touch" id="toTop">
    <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
      <div class="top">
        <img class="menuIcon" src="@/assets/images/back_whhyf_icon@2x.png" @click="$router.go(-1)" />
        <div class="searchBox" @click="$router.push('/search')">
          <img class="searchIcon" src="@/assets/images/search_comm_icon@2x.png" />
          <span class="searchInput">请输入关键字搜索</span>
          <!-- <input class="searchInput" type="text" placeholder="请输入关键字搜索" disabled /> -->
        </div>
      </div>
      <div class="content">
        <div class="banner">
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#CA001B">
            <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="imgclick(item.UrlLink)">
              <img class="bannerImg" :src="item.File_Url" />
            </van-swipe-item>
          </van-swipe>
        </div>
        <div class="title">
          <img :src="code == 'dklh' ? require('@/assets/images/dklh.png') : require('@/assets/images/zqnjx.png')" />
          <span>{{title}}</span>
        </div>
        <div class="departmentBox">
          <div class="departmentItem" :class="{'departmentActive': activeTCode == ''}" @click="changeDepartment('', '', '全部')">
            <span>全部</span>
          </div>
          <div class="departmentItem" :class="{'departmentActive': activeTCode == item.TCode}" v-for="(item, index) in codeList" :key="index" @click="changeDepartment(item.Code, item.TCode, item.Name)">
            <span>{{item.Name}}</span>
          </div>
        </div>
        <div class="listBox">
          <div class="classify_content">
            <div>
              <span class="classify_item" :class="{'classify_item_active': ModuleType == 1}" @click="changeModuleType(1)">最新上线</span>
              <span class="classify_item" :class="{'classify_item_active': ModuleType == 2}" v-if="code == 'dklh'" @click="changeModuleType(2)">热门推荐</span>
              <span class="classify_item" :class="{'classify_item_active': ModuleType == 8}" v-if="code == 'zqnjx'" @click="changeModuleType(8)">人气排行</span>
            </div>
            <div class="more" v-if="activeName == '心外'" @click="showPicker">
              <span>全部</span>
              <img src="@/assets/images/more_comm_icon@2x.png" />
            </div>
            <van-popup v-model="showXinWaiPicker" round position="bottom" :style="{ height: '30%' }">
              <div class="pickerContent">
                <div class="van-picker__toolbar toolbar">
                  <button type="button" class="van-picker__cancel" @click="onXinWaiPickerCancel">取消</button>
                  <button type="button" class="van-picker__confirm" @click="onXinWaiPickerConfirm">确认</button>
                </div>
                <div class="picker_content">
                  <div class="pickerBox pickerBoxCenter">
                    <div class="picker_block" :class="{'activeBg': item.isChecked}" v-for="(item, index) in tempXinwaiCodeList" :key="index" @click="selectXinWai(index)">{{item.Name}}</div>
                  </div>
                </div>
              </div>
            </van-popup>
          </div>
          <div class="listContent">
            <div id="dataList">
              <waterfall :col="2" :width="itemWidth" :gutterWidth="gutterWidth" :data="list">
                <template>
                  <div class="list" v-for="(item, index) in list" :key="index" @click="toContentPage(item.Id, item.Type)">
                    <div class="item_img">
                      <img class="item_cover" :src="item.Thumbnail" />
                      <img class="playIcon" v-if="item.Type == 1 || item.Type == 3" src="@/assets/images/play_comm_icon@2x.png" />
                    </div>
                    <div class="item_content">
                      <span class="item_title">{{item.Title}}</span>
                      <p class="item_time">{{item.yyyymmdd}}</p>
                      <div class="item_nums">
                        <div class="nums">
                          <img src="@/assets/images/browse_comm_icon@2x.png" />
                          <span>{{item.WatchCount}}</span>
                        </div>
                        <div class="nums">
                          <img src="@/assets/images/like_comm_icon@2x.png" />
                          <span>{{item.LikeCount}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </waterfall>
            </div>
          </div>
        </div>
      </div>
    </mescroll-vue>
  </div>
</template>

<script>
import mixin from '../../common/mixin/mixins'
import MescrollVue from 'mescroll.js/mescroll.vue'
export default {
  name: 'OperativeMonthList',
  data() {
    return {
      bannerList: [],
      codeList: [],
      activeCode: '',
      activeTCode: '',
      activeName: '',
      ModuleType: 1,
      sonColumnTCode: '',
      list: [],
      PageIndex: 0,
      PageSize: 6,
      mescroll: null, // mescroll实例对象
      mescrollDown:{
        use: false,
      },
      mescrollUp: { // 上拉加载的配置.
        isBounce: false,
			  callback: this.upCallback, // 上拉回调
				page: {
					num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
					size: 6 //每页数据条数,默认10
				},
        noMoreSize: 0,
				htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
        toTop: {
          warpId : 'toTop',
          src: './static/image/top_comm_icon@2x.png' // 回到顶部按钮的图片路径,支持网络图
        },
        empty: {
          // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
          warpId: 'dataList', // 父布局的id;
          icon: './static/image/none_comm_png@2x.png', // 图标,支持网络图
          tip: '敬请期待', // 提示
        },
			},
      showXinWaiPicker: false,
      xinwaiCodeList: [],
      tempXinwaiCodeList: []
    }
  },
  mixins: [mixin],
  components: {
    MescrollVue
  },
  computed: {
    code() {
      return this.$route.query.code;
    },
    tcode() {
      return this.$route.query.tcode;
    },
    title() {
      return this.$route.query.title;
    },
    itemWidth() {
      return (340 * 0.5 * (document.documentElement.clientWidth / 375))
    },
    gutterWidth() {
      return (20 * 0.5 * (document.documentElement.clientWidth / 375))
    }
  },
  watch: {
    activeTCode() {
      this.getXinWaiCodeList();
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
    },
    ModuleType() {
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
    },
  },
  created() {
    this.getBanner();
    this.getCodeList();
  },
  methods: {
    // 获取Banner
    getBanner() {
      this.$axios.get('/Api/api/Web/ImgFile/GetImgFileListByCode?Code=' + this.code).then(res => {
        this.bannerList = res.Data;
      })
    },
    getCodeList() {
      this.$axios.get('/Api/Api/Web/Article/GetCodeList?pcode=' + this.code).then(res => {
        this.codeList = res.Data;
      })
    },
    getXinWaiCodeList() {
      this.$axios.get('/Api/Api/Web/Article/GetCodeList?pcode='+ this.activeCode).then(res => {
        this.xinwaiCodeList = res.Data;
        this.tempXinwaiCodeList = res.Data;
      })
    },
    changeDepartment(code, tcode, name) {
      this.activeCode = code;
      this.activeTCode = tcode;
      this.activeName = name;
      this.ModuleType = 1;
      this.sonColumnTCode = "";
      this.BuriedPoint(1, name, tcode, `/operativeMonthList?code=${this.code}&tcode=${this.tcode}&title=${this.title}`);
    },
    changeModuleType(Type) {
      this.ModuleType = Type;
    },
    // mescroll组件初始化的回调,可获取到mescroll对象
		mescrollInit (mescroll) {
			this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
		},
    // 上拉回调
		upCallback (page) {
      if(this.sonColumnTCode) {
        this.getList1(page.num, page.size);
      } else {
        this.getList(page.num, page.size);
      }
		},
    // 获取列表数据
    getList(PageIndex, PageSize) {
      this.PageIndex = PageIndex;
      this.PageSize = PageSize;
      setTimeout(() => {
        this.$axios.post('/Api/Api/Web/Article/GetArticleList', {
          SubjectTCode: this.activeTCode == "" ? this.tcode : this.activeTCode,
          ModuleType: this.ModuleType,
          PageIndex: this.PageIndex,
          PageSize: this.PageSize
        }).then((res) => {
          let arr = res.Data.List;
          // 如果是第一页需手动置空列表
          if (PageIndex === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr);
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            this.mescroll.endSuccess(arr.length)
          })
        }).catch((e) => {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          this.mescroll.endErr()
        })
      }, 200);
    },
    // 获取列表数据
    getList1(PageIndex, PageSize) {
      this.PageIndex = PageIndex;
      this.PageSize = PageSize;
      setTimeout(() => {
        this.$axios.post('/Api/Api/Web/Article/GetArticleList', {
          ColumnTCode: this.sonColumnTCode,
          ModuleType: this.ModuleType,
          PageIndex: this.PageIndex,
          PageSize: this.PageSize
        }).then((res) => {
          let arr = res.Data.List;
          // 如果是第一页需手动置空列表
          if (PageIndex === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr);
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            this.mescroll.endSuccess(arr.length)
          })
        }).catch((e) => {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          this.mescroll.endErr()
        })
      }, 200);
    },
    toContentPage(Id, Type) {
      this.$router.push({
        path: 'contentPage',
        query: {
          code: this.code,
          id: Id,
          type: Type
        }
      });
    },
    imgclick(UrlLink) {
      let objExp=new RegExp(/^(f|ht)tps?:\/\//i);
      if(UrlLink) {
				if(objExp.test(UrlLink)) {
				  location.href = UrlLink;
				}else{
					this.$router.push(UrlLink)
				}
      }
    },
    showPicker() {
      if(this.activeName == '心外') {
        this.showXinWaiPicker = true;
        this.tempXinwaiCodeList = JSON.parse(JSON.stringify(this.xinwaiCodeList));
      }
    },
    onXinWaiPickerCancel() {
      if(this.activeName == '心外') {
        this.showXinWaiPicker = false;
        this.tempXinwaiCodeList = JSON.parse(JSON.stringify(this.xinwaiCodeList));
      }
    },
    onXinWaiPickerConfirm() {
      if(this.activeName == '心外') {
        this.showXinWaiPicker = false;
        this.xinwaiCodeList = JSON.parse(JSON.stringify(this.tempXinwaiCodeList));
        this.sonColumnTCode = "";
        this.xinwaiCodeList.forEach(item => {
          if(item.isChecked) {
            this.sonColumnTCode += item.TCode + ',';
          }
        });
        this.sonColumnTCode = this.sonColumnTCode.substr(0, this.sonColumnTCode.length - 1);
        this.list = [];
        this.mescroll.resetUpScroll() // 刷新列表数据
      }
    },
    selectXinWai(index) {
      this.tempXinwaiCodeList[index].isChecked = !this.tempXinwaiCodeList[index].isChecked;
      this.$forceUpdate();
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-image: url('../../assets/images/bg_comm_png@2x.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #F9F9F9;
  .mescroll {
    position: fixed;
    top: 0;
    bottom: 0;
    height: auto;
    padding: 15px 0 0;
    box-sizing: border-box;
  }
  .top {
    margin-bottom: 15px;
    display: flex;
    padding: 0 12px;
    .menuIcon {
      width: 30px;
      height: 30px;
    }
    .searchBox {
      display: flex;
      align-items: center;
      flex: 1;
      height: 30px;
      line-height: 30px;
      border-radius: 30px;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 0 15px;
      margin-left: 10px;
      .searchIcon {
        width: 14px;
        height: 14px;
      }
      .searchInput {
        flex: 1;
        margin-left: 4px;
        border: none;
        background-color: transparent;
        color: #999999;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #F9F9F9;
    border-radius: 12px 12px 0 0;
    .banner {
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 4px 12px #ebedf0;
      margin: 15px 12px 0;
      .bannerImg {
        display: block;
        width: 100%;
      }
      .goBack {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 30px;
        height: 30px;
      }
    }
    .title {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #CA001B;
      font-weight: bold;
      margin: 15px 12px;
      img {
        width: 20px;
        margin-right: 10px;
      }
    }
    .departmentBox {
      position: relative;
      height: 30px;
      padding: 0 12px;
      box-sizing: border-box;
      border-bottom: 1px solid #969696;
      .departmentItem {
        position: absolute;
        display: inline-block;
        width: 20.8%;
        height: 30px;
        line-height: 33px;
        font-size: 16px;
        color: #333333;
        padding-left: 14px;
        box-sizing: border-box;
        background-image: url('../../assets/images/jcssy_bg.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
      .departmentItem:nth-child(1) {
        z-index: 9;
      }
      .departmentItem:nth-child(2) {
        z-index: 8;
        left: 21.6%;
      }
      .departmentItem:nth-child(3) {
        z-index: 7;
        left: 40.1%;
      }
      .departmentItem:nth-child(4) {
        z-index: 6;
        left: 58.6%;
      }
      .departmentItem:nth-child(5) {
        z-index: 5;
        left: 77.1%;
      }
      .departmentActive {
        color: #FFFFFF;
        background-image: url('../../assets/images/jcssy_bg1.png');
      }
    }
    .listBox {
      display: flex;
      flex-direction: column;
      flex: 1;
      background-color: #FFFFFF;
      .classify_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
        box-sizing: border-box;
        margin-top: 5px;
        .classify_item {
          position: relative;
          display: inline-block;
          height: 38px;
          line-height: 38px;
          font-size: 15px;
          color: #333333;
        }
        .classify_item:not(:last-of-type) {
          margin-right: 30px;
        }
        .classify_item_active {
          color: #CA001B;
        }
        .classify_item_active::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          border-radius: 2px;
          background-color: #CA001B;
        }
        .more {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #CA001B;
          img {
            width: 12px;
            height: 12px;
            margin-left: 3px;
          }
        }
      }
      .listContent {
        flex: 1;
        background-color: #F9F9F9;
        border-radius: 12px 12px 0 0;
        padding: 12px;
        box-sizing: border-box;
        .list {
          width: 100%;
          background-color: #FFFFFF;
          border-radius: 4px;
          overflow: hidden;
          margin-bottom: 10px;
          .item_img {
            position: relative;
            .item_cover {
              display: block;
              width: 100%;
            }
            .playIcon {
              position: absolute;
              right: 12px;
              bottom: 12px;
              width: 30px;
              height: 30px;
            }
            .liveStatu {
              display: flex;
              align-items: center;
              position: absolute;
              top: 0;
              right: 0;
              font-size: 11px;
              color: #CA001B;
              padding: 2px 8px;
              background-color: #F9E5E8;
              border-radius: 0 0px 0 10px;
            }
          }
          .item_content {
            padding: 5px 8px 8px;
            .item_title {
              font-size: 14px;
              color: #333333;
              overflow: hidden;
              text-overflow:ellipsis;
              display:-webkit-box;
              -webkit-box-orient:vertical;
              -webkit-line-clamp:2;
              word-wrap: break-word;
            }
            .item_titleHeight {
              height: 38px;
            }
            .timeAndNum {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            .item_time {
              font-size: 12px;
              color: #999999;
              line-height: 24px;
            }
            .item_label {
              display: flex;
              flex-wrap: wrap;
              span {
                display: inline-block;
                height: 20px;
                line-height: 20px;
                border-radius: 2px;
                padding: 0 6px;
                margin-right: 6px;
                margin-bottom: 5px;
                background-color: rgba(255, 73, 98, 0.15);
                font-size: 11px;
                color: #CA001B;
              }
            }
            .item_nums {
              display: flex;
              justify-content: flex-end;
              .nums {
                display: flex;
                align-items: center;
                img {
                  width: 12px;
                  margin: 0 2px 0 6px;
                }
                span {
                  font-size: 11px;
                  color: #666666;
                }
              }
            }
          }
        }
      }
    }
    .picker_content {
      padding: 12px 6px;
      .pickerBox {
        display: flex;
        flex-wrap: wrap;
      }
      .pickerBoxCenter {
        justify-content: center;
      }
      .picker_block {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(calc(100% / 3) - 12px);
        height: 36px;
        text-align: center;
        border: 1px solid rgba(153, 153, 153, 0.5);
        box-sizing: border-box;
        margin: 6px;
        border-radius: 4px;
        color: #333333;
        font-size: 14px;
      }
      .activeBg {
        border: none;
        color: #C70C3C;
        background-image: url('../../assets/images/select_system_btn@2x.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}
</style>